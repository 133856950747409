import { AuthContext } from "Contexts";
import { useContext } from "react";
import { Navigate } from "react-router-dom";


export default function HomePage() {
    const user = useContext(AuthContext);
    if (user) {
        return (<>
            <div>Authenticated</div>
            <Navigate to="/main" replace={true} />
        </>);
    }
    return <div>Loading</div>;
}