import { ReactElement } from "react";
import HomePage from "routes/home-page/HomePage";
import { RetoolPage } from "routes/retool-page/RetoolPage";
import UnauthorizedPage from "routes/unauthorized-page/UnauthorizedPage";

export interface RouteData {
    path: string;
    component: ReactElement;
    name: string;
    children?: RouteData[];
    navigationEnabled: boolean;
}

export const routes: RouteData[] = [

    {
        path: "/",
        name: "Home",
        component: <HomePage />,
        navigationEnabled: false
    },
    // {
    //     path: "/orders",
    //     name: "Orders",
    //     component: <RetoolPage retoolAppId={process.env.REACT_APP_ORDERS_APP_UUID ?? ""} />,
    //     navigationEnabled: true
    // },
    {
        path: "/main",
        name: "Multi View",
        component: <RetoolPage retoolAppId={process.env.REACT_APP_MULTIVIEW_UUID ?? ""} />,
        navigationEnabled: true
    },
    {
        path: "/unauthorized",
        name: "",
        component: <UnauthorizedPage />,
        navigationEnabled: false
    }
];